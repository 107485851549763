@tailwind base;
@tailwind components;
@tailwind utilities;

h3 {
  @apply text-dark-blue font-sb text-[12px] lg:text-[16px];
}

a {
  @apply text-blue underline;
}

/* GRAPH */
tspan {
  @apply text-[12.6px];
}

@font-face {
  font-family: "SofiaPro-Regular";
  src: url("../../public/fonts/SofiaPro-Regular/font.woff");
}

@font-face {
  font-family: "SofiaPro-SemiBold";
  src: url("../../public/fonts/SofiaPro-SemiBold/font.woff");
}

.md-size-tab button {
  font-size: 16px !important;
  font-family: "SofiaPro-SemiBold", "sans-serif" !important ;
  text-transform: none !important;
  color: #11154f;
}

@media screen and (max-width: 1023px) {
  .md-size-proce svg {
    width: 38px;
    height: 38px;
  }
  .md-size-graph svg {
    width: 410px;
    height: 258px;
  }
  .md-size-graph div {
    width: 394px !important;
    height: 208px !important;
  }
  .md-size-plus svg {
    width: 20px;
    height: 20px;
  }
  .md-size-cross svg {
    width: 11px;
    height: 11px;
  }
  .md-size-tab button {
    font-size: 10px !important;
  }
}
