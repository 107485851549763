input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

body {
  scrollbar-width: thin; /* "auto" or "thin" */
}

.bg-color-components {
  background: linear-gradient(180deg, #f1f4fa 0%, #dfe6f4 100%) 0% 0% no-repeat
    padding-box;
}
.btn-pro:hover {
  background-color: rgb(51 51 255);
}

.css-19kzrtu {
  padding: 15px 0px 0px 0px !important;
}

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-align: left;
  text-transform: none !important;
  color: #11154f !important;
  font-family: "SofiaPro-SemiBold", "sans-serif" !important ;
  font-size: 16px !important;
} */

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  @apply md:text-xs !important;
} */

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #0333ff !important;
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #0333ff !important;
}

.modal-hubspot {
  background: #fff;
  border: 2px solid #c3cfe9;
  border-radius: 20px;
  z-index: 999;
  max-width: 420px;
  margin: auto;
  padding: 1em 2em 2em;
  position: relative;
  top: 10%;
  height: fit-content;
}

.modal-pop {
  background: #fff;
  border: 2px solid #c3cfe9;
  border-radius: 20px;
  z-index: 999;
  max-width: 620px;
  margin: auto;
  padding: 1em 2em 2em;
  position: relative;
  top: 10%;
  overflow-y: scroll;
  scrollbar-width: thin;
  height: 85vh;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal_bg {
  @apply fixed top-0 left-0 w-full h-[100vh] opacity-100 z-50 backdrop-blur bg-white/[.33];
}

.modal-overlay {
  @apply fixed top-0 left-0 w-full h-full outline-none backdrop-sepia-0 overflow-x-hidden overflow-y-auto z-10 h-full w-full bg-opacity-90;
}

ul li {
  font-size: 14px;
  margin-left: 10px;
  margin-top: 10px;
  list-style-type: circle;
  color: #050b3d;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
